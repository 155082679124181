import React, { useState } from "react";
import axios from "axios";
import "./SectionListComponent.css";
import Demographics from "../Demographic/Demographics";
import { useSelector } from "react-redux";
import GetCookie from '../../../HIPCookies/getCookie';
import ClipLoader from "react-spinners/ClipLoader";

const SectionListComponent = ({ sectionsData, onBackClick, fileName }) => {
  const [selectedSections, setSelectedSections] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [sectionSummaries, setSectionSummaries] = useState({});
  const [loading, setLoading] = useState(false);
  const theme = useSelector((state) => state.messages.Theme) || ""
  const toggleSectionSelection = (section) => {
    if (selectedSections.includes(section)) {
      setSelectedSections(selectedSections.filter((s) => s !== section));
    } else {
      setSelectedSections([...selectedSections, section]);
    }
  };
  const handleRemoveSection = (section) => {
    setSelectedSections(selectedSections.filter((s) => s !== section));
  };
  const selectAllSections = () => {
    setSelectedSections(sectionsData);
  };

  const deselectAllSections = () => {
    setSelectedSections([]);
  };

  let userId = (JSON.parse(GetCookie('userInfo')) || {}).userId;

  const formData1 = new FormData();
  formData1.append("user_id", userId);
  formData1.append("selected_sections", JSON.stringify(selectedSections));

  const handleSummarizeClick = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_CONNECT}/ccda-summ/summarize_selected_sections`,
        formData1,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = response.data;
      setSectionSummaries(data.section_summaries);
      setShowSummary(true);
    } catch (error) {
      console.error("Error summarizing sections:", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className={`${theme}section-list-container`}>
      {loading && (
        <div className='loading_screen'>
          <ClipLoader
            color={"#fff"}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}

      <div className={`${theme}ccda-document-info`}>
        <p>
          You can{" "}
          <a href="/hip-connect/summarize-document" onClick={onBackClick}>
            <strong style={{ color: "#337AB7" }}>CLICK HERE</strong>
          </a>{" "}
          to view another C-CDA Document
        </p>
        <p>
          A cutting-edge application that effortlessly transforms CCDA health
          documents into concise, organized and easy to understand summaries.
          HealthBrief empowers users to swiftly generate comprehensive overviews
          of various document sections or select individual sections for
          in-depth insights. Designed for healthcare professionals, researchers,
          and patients, HealthBrief streamlines the extraction of essential
          information, enhancing informed decision-making and communication in
          the medical realm.
        </p>
        <h4 style={{ color: theme === 'dark_' ? "white" : "black" }}>File name: {fileName}</h4>
      </div>
      <Demographics />

      <div>
        <h3 style={{ textAlign: "center", marginTop: "0px", color: theme === 'dark_' ? "white" : "black" }}>Sections List</h3>
        <div className={`${theme}sections-actions`}>
          <button className={`${theme}select-all-button`} onClick={selectAllSections}>
            Select All
          </button>
          <button className={`${theme}deselect-all-button`} onClick={deselectAllSections}>
            Deselect All
          </button>
        </div>
        <div className={`${theme}sections-container`}>
          {sectionsData.map((section, index) => (
            <div
              key={index}
              className={`${theme}section-box ${selectedSections.includes(section) ? "selected" : ""
                }`}
            >
              <label>
                <input
                  type="checkbox"
                  checked={selectedSections.includes(section)}
                  onChange={() => toggleSectionSelection(section)}
                />
                {section}
              </label>
            </div>
          ))}
        </div>
        {selectedSections.length > 0 && (
          <h3
            style={{
              textAlign: "center",
              marginTop: "0px",
              color: theme === 'dark_' ? "white" : "black"
            }}
          >
            Selected Sections:
          </h3>
        )}
        <div>
          {selectedSections.length > 0 ? (
            <div className={`${theme}selected-sections-box`}>
              <ul>
                {selectedSections.map((section, index) => (
                  <li key={index}>
                    {" "}
                    <span>{section}</span>
                    <span
                      className={`${theme}section-cross`}
                      onClick={() => handleRemoveSection(section)}
                    >
                      ×
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <h4
              style={{ textAlign: "center", color: "grey", marginTop: "0px", }}
            >
              No Section Selected
            </h4>
          )}
          <button className="summarize-button" onClick={handleSummarizeClick}>
            Summarize Sections
          </button>
        </div>
      </div>

      {showSummary && (
        <div className={`${theme}section-summaries`}>
          <h3 style={{ color: theme === 'dark_' ? "white" : "black" }}>Section Summaries</h3>
          <table>
            <thead>
              <tr>
                <th>Section</th>
                <th>Summary</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(sectionSummaries).map(
                ([section, summary], index) => (
                  <tr key={index}>
                    <td>{section}</td>
                    <td>{summary}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SectionListComponent;