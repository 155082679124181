import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Typography, Select, MenuItem, Button, Grid, Pagination, PaginationItem, TextField, ListItemText, Box, Drawer, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { IconButton, Menu } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./UserProfile.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SearchImage from "../../../assets/Icons/Search.svg";
import GetCookie from '../../../HIPCookies/getCookie';

function UserProfile() {
    const [tableData, setTableData] = useState([]);
    const [numberOfRows, setNumberOfRows] = useState(10);
    const [Page, setPage] = useState(0);
    const [rows, setRows] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [PageNumber, setPageNumber] = useState(1);
    const [selectAll, setSelectAll] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [selectedUserRole, setSelectedUserRole] = useState([]);
    const [selectedUserGroup, setSelectedUserGroup] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [sortOrderUser, setSortOrderUser] = useState("asc");
    const [sortOrderUserRole, setSortOrderUserRole] = useState("asc");
    const [sortOrderUserGroup, setSortOrderUserGroup] = useState("asc");
    const [sortOrderSecurityGroup, setSortOrderSecurityGroup] = useState("asc");
    const [userRoles, setUserRoles] = useState([]);
    const [userGroups, setUserGroups] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [textDialogbox, setTextDialogbox] = useState("");
    const [active, setActive] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [editedUserRole, setEditedUserRole] = useState([]);
    const [editedUserGroup, setEditedUserGroup] = useState([]);
    const [deletedUserId, setdeletedUserId] = useState([]);
    const [editedUserRoleNames, setEditedUserRoleNames] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [tableDatachanges, setTableDatachanges] = useState(false);
    const jWTToken = (JSON.parse(GetCookie('token')) || {}).token;
    const roleData = JSON.parse(GetCookie('roleInfo'));
    const isSuperAdmin = roleData.some(role => role.roleName === 'Super Administrator');
    const [userProfileImages, setUserProfileImages] = useState([]);
    const [selectedUserData, setSelectedUserData] = useState({
        displayName: "",
        userRoleDisplayName: [],
        userGroupDisplayName: "",
    });

    let editUserGroupsAsString = '';
    if (editedUserGroup.includes(',')) {
        editUserGroupsAsString = editedUserGroup.length > 0 ? editedUserGroup?.split(',').join('\n') : "";
    } else {
        if (Array.isArray(editedUserGroup)) {
            editUserGroupsAsString = editedUserGroup.length > 0 ? editedUserGroup.join('\n') : "";
        } else {
            editUserGroupsAsString = editedUserGroup.length > 0 ? editedUserGroup : "";
        }
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/AllUsers`, {
            params: {
                userRole: selectedUserRole,
                userGroup: selectedUserGroup,
            },
            headers: {
                'Authorization': `Bearer ${jWTToken}`
            },
        })
            .then((response) => {
                setTableData(response.data);
                // Initialize Page to 0
                setPage(0);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                toast.error(`Error fetching data.`, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }, [tableDatachanges, selectedUserRole, selectedUserGroup, numberOfRows]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/UserImage/0`, {
            headers: {
                'Authorization': `Bearer ${jWTToken}`
            },
        })
            .then((response) => {
                // Iterate through the array and process each record
                const decodedImages = response.data.map((record) => {
                    const { id, imageFilepath } = record;
                    // Check if the imageFilepath length is greater than 2
                    if (imageFilepath.length > 2) {
                        // Decode the base64-encoded image data
                        const decodedImage = atob(imageFilepath);
                        return { id, decodedImage };
                    } else {
                        return { id, imageFilepath };
                    }
                });
                // Set the decoded images in the state
                setUserProfileImages(decodedImages);
            })
            .catch((error) => {
                console.error("Error fetching image data:", error);
            });
    }, [tableDatachanges, selectedUserRole, selectedUserGroup, numberOfRows]);

    useEffect(() => {
        // When tableData changes, update the filteredData based on filters
        const filteredRows = tableData.filter((item) => {
            if (item.isActive) {
                const matchesUserRole =
                    selectedUserRole.length === 0 ||
                    item.userRoleDisplayName.includes(selectedUserRole[0]);
                const matchesUserGroups =
                    selectedUserGroup.length === 0 ||
                    selectedUserGroup.some((group) =>
                        item.userGroupDisplayName.includes(group)
                    );
                // Check if any field matches the search query
                const matchesSearchQuery =
                    item.displayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    item.userRoleDisplayName
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    item.userGroupDisplayName
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase());
                return matchesUserRole && matchesUserGroups && matchesSearchQuery;
            }
            return false; // Inactive users are excluded
        });

        setFilteredData(filteredRows);
        setTotalRows(filteredRows.length);
        setTotalPages(Math.ceil(filteredRows.length / numberOfRows));
    }, [tableData, selectedUserRole, selectedUserGroup, numberOfRows, searchQuery, PageNumber]);

    useEffect(() => {
        // Call the fetch functions to populate user roles and user groups
        fetchUserRoles();
        fetchUserGroups();

        // Initialize Page and rows to display the first page
        setPage(0);
        setRows(numberOfRows); // Set rows to the initial page size
    }, []);

    // Function to fetch user roles data from the API
    const fetchUserRoles = () => {
        axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/UserRole`, {
            headers:
            {
                'Authorization': `Bearer ${jWTToken}`,
                groupRelation: true,
            },
        }).then((response) => {
            setUserRoles(response.data);
        }).catch((error) => {
            console.error("Error fetching user roles:", error);
            toast.error(`Error fetching  user roles.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        });
    };

    // Function to fetch user groups data from the API
    const fetchUserGroups = () => {
        axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/UserGroup`, {
            headers: {
                'Authorization': `Bearer ${jWTToken}`
            },
        }) // use API_ENDPOINT
            .then((response) => {
                setUserGroups(response.data);
            })
            .catch((error) => {
                console.error("Error fetching user groups:", error);
                toast.error(`Error fetching  user groups.`, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPageNumber(1); // Reset to the first page
    };

    const handleUserRoleChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue === "All User Role") {
            setSelectedUserRole([]); // Reset the selected user role filter
        } else {
            setSelectedUserRole([selectedValue]);
        }
        setPageNumber(1); // Reset to the first page
        setPage(0);
    };

    const handleUserGroupChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue === "All User Group") {
            setSelectedUserGroup([]); // Reset the selected user group filter
        } else {
            setSelectedUserGroup([selectedValue]);
        }
        setPageNumber(1); // Reset to the first page
        setPage(0);
    };

    const handlePageChange = (_, newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPageNumber(newPage);
        }
    };

    const handlePageSizeChange = (event) => {
        const newPageSize = event.target.value;
        setNumberOfRows(newPageSize);
        setPageNumber(1); // Reset to the first page
    };

    const handleToggleSelect = (itemId, itemName) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.some((item) => item.id === itemId)) {
                return prevSelectedItems.filter((item) => item.id !== itemId);
            } else {
                return [...prevSelectedItems, { id: itemId, name: itemName }];
            }
        });
    };

    const handleToggleSelectAll = () => {
        if (!selectAll) {
            // If not currently selected, select all items
            setSelectedItems(filteredData.map((item) => ({ id: item.id, name: item.userRoleDisplayName })));
        } else {
            // If currently selected, unselect all items
            setSelectedItems([]);
        }
        setSelectAll(!selectAll);
    };

    const handleOpenMenu = (event, itemId) => {
        setAnchorEl(event.currentTarget);
        setSelectedRowId(itemId);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setSelectedRowId(null);
    };

    const handleSidebarToggle = (userId) => {
        handleCloseMenu();
        const selectedUser = tableData.find((user) => user.id === userId);
        axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/AllUserRole/${userId}`, {
            headers: {
                'Authorization': `Bearer ${jWTToken}`
            },
        }) // use API_ENDPOINT
            .then((response) => {
                const dataEditUserRole = response.data;
                const activeRoles = dataEditUserRole.filter(
                    (item) => item.isActive === 1
                );
                const editedUserRole = activeRoles.map((item) => item.id);
                setEditedUserRole(editedUserRole);
            })
            .catch((error) => {
                console.error("Error fetching user roles:", error);
                toast.error(`Error fetching  user roles.`, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
        if (selectedUser) {
            setSelectedUserId(userId);
            setSelectedUserData({
                displayName: selectedUser.displayName,
                userRoleDisplayName: selectedUser.userRoleDisplayName,
                userGroupDisplayName: selectedUser.userGroupDisplayName,
            });

            setEditedUserRole([selectedUser.userRoleDisplayName]);
            setEditedUserGroup(selectedUser.userGroupDisplayName);
            setIsSidebarOpen(true);
        }
    };

    const functionDeleteOpenPopup = (values) => {
        // Check if any selected role has the name "Super Administrator"
        const hasSuperAdministrator = values.some(item => item.name.includes('Super Administrator'));
        if (hasSuperAdministrator && isSuperAdmin !== 'Super Administrator') {
            toast.error(`Cannot delete user with 'Super Administrator' role.`, {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }
        setActive(true);
        setTextDialogbox(`Do you want to Delete User?`);
        const selectedIds = values.map(item => item.id);
        setdeletedUserId(selectedIds);
        handleCloseMenu();
    };

    const functionDeleteOpenPopupSingle = (values) => {
        setActive(true);
        setTextDialogbox(`Do you want to Delete User?`);
        setdeletedUserId(values);
        handleCloseMenu();
    };

    const closeDialogPopup = () => {
        setActive(false);
    };

    const handleDeleteUsers = () => {
        handleCloseMenu();
        axios.delete(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Users`, {
            headers: {
                'Authorization': `Bearer ${jWTToken}`,
                UserIds: deletedUserId,
            },
        })
            .then((response) => {
                if (response.status === 204) {
                    toast.success(`Deleted successfully.`, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTableDatachanges(!tableDatachanges); // Toggle the state to trigger a refresh
                } else {
                    toast.error("Failed to delete User.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(`An error occurred while deleting the entry.`, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });

        setSelectedItems([]);
        closeDialogPopup();
    };

    // handle closing of sidebar
    const handleCancel = () => {
        // Close the sidebar and clear the editedOption
        setIsSidebarOpen(false);
    };

    const handleEditedUserRoleChange = (event) => {
        const selectedIds = event.target.value;
        const selectedNames = selectedIds.map(
            (id) => userRoles.find((option) => option.id === id)?.displayName
        );

        setEditedUserRole(selectedIds);
        setEditedUserRoleNames(selectedNames);
    };

    const handleSelectUserRoleClose = () => {
        if (editedUserRole.length > 0) {
            axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/UserGroup/RoleIds`,
                {
                    headers: {
                        'Authorization': `Bearer ${jWTToken}`,
                        UserRoleIds: editedUserRole,
                    },
                })
                .then((response) => {
                    const idArrayGroup = response.data.map((item) => item.displayName);
                    setEditedUserGroup(idArrayGroup);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(`An error occurred while getting User Groups.`, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                });
        } else {
            setEditedUserGroup("");
        }
    };

    const handleSortUser = () => {
        if (filteredData && filteredData.length > 0) {
            const sortedData = [...filteredData];
            if (sortOrderUser === "asc") {
                sortedData.sort((a, b) => {
                    // Check if displayName is defined before comparing
                    if (a.displayName && b.displayName) {
                        return a.displayName.localeCompare(b.displayName);
                    }
                    return 0; // Handle cases where displayName is undefined
                });
                setSortOrderUser("desc");
            } else {
                sortedData.sort((a, b) => {
                    if (a.displayName && b.displayName) {
                        return b.displayName.localeCompare(a.displayName);
                    }
                    return 0;
                });
                setSortOrderUser("asc");
            }
            setFilteredData(sortedData);
        }
    };

    const handleSortUserRole = () => {
        if (filteredData && filteredData.length > 0) {
            const sortedData = [...filteredData];
            if (sortOrderUserRole === "asc") {
                sortedData.sort((a, b) => {
                    // Check if userRoleDisplayName is defined before comparing
                    if (a.userRoleDisplayName && b.userRoleDisplayName) {
                        return a.userRoleDisplayName.localeCompare(b.userRoleDisplayName);
                    }
                    return 0; // Handle cases where userRoleDisplayName is undefined
                });
                setSortOrderUserRole("desc");
            } else {
                sortedData.sort((a, b) => {
                    if (a.userRoleDisplayName && b.userRoleDisplayName) {
                        return b.userRoleDisplayName.localeCompare(a.userRoleDisplayName);
                    }
                    return 0;
                });
                setSortOrderUserRole("asc");
            }
            setFilteredData(sortedData);
        }
    };

    const handleSortUserGroup = () => {
        if (filteredData && filteredData.length > 0) {
            const sortedData = [...filteredData];
            if (sortOrderUserGroup === "asc") {
                sortedData.sort((a, b) => {
                    // Check if userGroupDisplayName is defined before comparing
                    if (a.userGroupDisplayName && b.userGroupDisplayName) {
                        return a.userGroupDisplayName.localeCompare(b.userGroupDisplayName);
                    }
                    return 0; // Handle cases where userGroupDisplayName is undefined
                });
                setSortOrderUserGroup("desc");
            } else {
                sortedData.sort((a, b) => {
                    if (a.userGroupDisplayName && b.userGroupDisplayName) {
                        return b.userGroupDisplayName.localeCompare(a.userGroupDisplayName);
                    }
                    return 0;
                });
                setSortOrderUserGroup("asc");
            }
            setFilteredData(sortedData);
        }
    };

    const handleSortSecurityGroup = () => {
        if (filteredData && filteredData.length > 0) {
            const sortedData = [...filteredData];
            sortedData.sort((a, b) => {
                // Check if securityGroupDisplayName is defined before comparing
                if (a.securityGroupDisplayName && b.securityGroupDisplayName) {
                    return a.securityGroupDisplayName.localeCompare(
                        b.securityGroupDisplayName
                    );
                }
                return 0; // Handle cases where securityGroupDisplayName is undefined
            });

            if (sortOrderSecurityGroup === "asc") {
                setSortOrderSecurityGroup("desc");
                sortedData.reverse(); // Reverse the order for descending
            } else {
                setSortOrderSecurityGroup("asc");
            }
            setFilteredData(sortedData);
        }
    };

    const handleSaveChanges = () => {
        axios.put(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/Users/${selectedUserId}`, {},
            {
                headers: {
                    'Authorization': `Bearer ${jWTToken}`,
                    UserRoleId: editedUserRole,
                },
            }).then((response) => {
                if (response.status === 200) {
                    toast.success(`User name has updated with User Role: ${editedUserRoleNames} and User Group: ${editedUserGroup}.`, {
                        position: toast.POSITION.TOP_CENTER,
                    });

                    setIsSidebarOpen(false);
                    setTableDatachanges(!tableDatachanges);
                } else {
                    toast.error("Failed to update User Roles.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }).catch((error) => {
                console.error(error);
                toast.error(`An error occurred while updating the entry.`, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    };

    return (
        <>
            <div className="user_config_span">
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item className="user_config_doc_search">
                        <input type={"search"} placeholder="Search by User, User Role or User Group" value={searchQuery} onChange={handleSearchChange} />
                        <img src={SearchImage} alt="search" />
                    </Grid>
                    <Grid item className="user_filter_buttons">
                        <Typography variant="subtitle1" className="user_filter_dropdowns_text" >  Filter by </Typography>
                        <div className="user_filter_dropdown">
                            <Select
                                name="userRole"
                                IconComponent={ExpandMoreIcon}
                                value={selectedUserRole.length > 0 ? selectedUserRole : ''}
                                onChange={handleUserRoleChange}
                                className="user_filter_dropdown_select"
                                fullWidth
                                displayEmpty
                                renderValue={(selected) =>
                                    selected.length === 0 ? "All User Role" : selected
                                }
                            >
                                <MenuItem className="user_filter_dropdown_select_content" value="All User Role" > All User Role </MenuItem>
                                {userRoles.map((role) =>
                                    <MenuItem className="user_filter_dropdown_select_content" key={role.id} value={role.displayName} >
                                        {role.displayName}
                                    </MenuItem>)
                                }
                            </Select>
                        </div>
                        <div className="user_filter_dropdown">
                            <Select
                                name="userGroup"
                                IconComponent={ExpandMoreIcon}
                                value={selectedUserGroup.length > 0 ? selectedUserGroup : ''}
                                onChange={handleUserGroupChange}
                                className="user_filter_dropdown_select"
                                fullWidth
                                displayEmpty
                                renderValue={(selected) =>
                                    selected.length === 0 ? "All User Group" : selected
                                }
                            >
                                <MenuItem className="user_filter_dropdown_select_content" value="All User Group" > All User Group </MenuItem>
                                {userGroups.map((group) => (
                                    <MenuItem className="user_filter_dropdown_select_content" key={group.id} value={group.displayName} >
                                        {group.displayName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <Button className="user_delete_btn" onClick={() => functionDeleteOpenPopup(selectedItems)} disabled={selectedItems.length === 0} >
                            Delete User
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <div className="user_table_display_container">
                <div className="user_table_display_table">
                    <table className="user_table_display_table_data">
                        <thead>
                            <tr>
                                <td className="form_check_container" style={{ width: "3%" }}>
                                    <Checkbox
                                        checked={selectAll}
                                        onChange={() => handleToggleSelectAll()}
                                        icon={<span className="form_check_input" />}
                                        checkedIcon={<CheckIcon className="form_check_input" />}
                                        color="primary"
                                        inputProps={{ "aria-label": "checkbox" }}
                                    />
                                </td>
                                <td style={{ width: "23%" }}> USER
                                    <IconButton className="form_asc_dsc_button" onClick={handleSortUser} >
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
                                            {sortOrderUser === "asc" ? (
                                                <React.Fragment>
                                                    <ArrowDropUpIcon style={{ marginBottom: -5, opacity: 0.5 }} />
                                                    <ArrowDropDownIcon fontSize="small" style={{ marginTop: -5 }} />
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <ArrowDropUpIcon fontSize="small" style={{ marginBottom: -5 }} />
                                                    <ArrowDropDownIcon style={{ marginTop: -5, opacity: 0.5 }} />
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </IconButton>
                                </td>
                                <td style={{ width: "23%" }}> USER ROLE
                                    <IconButton className="form_asc_dsc_button" onClick={handleSortUserRole} style={{ padding: 1 }} >
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
                                            {sortOrderUserRole === "asc" ? (
                                                <React.Fragment>
                                                    <ArrowDropUpIcon style={{ marginBottom: -5, opacity: 0.5 }} />
                                                    <ArrowDropDownIcon fontSize="small" style={{ marginTop: -5 }} />
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <ArrowDropUpIcon fontSize="small" style={{ marginBottom: -5 }} />
                                                    <ArrowDropDownIcon style={{ marginTop: -5, opacity: 0.5 }} />
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </IconButton>
                                </td>
                                <td style={{ width: "23%" }}> USER GROUP
                                    <IconButton className="form_asc_dsc_button" onClick={handleSortUserGroup} style={{ padding: 1 }} >
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
                                            {sortOrderUserGroup === "asc" ? (
                                                <React.Fragment>
                                                    <ArrowDropUpIcon style={{ marginBottom: -5, opacity: 0.5 }} />
                                                    <ArrowDropDownIcon fontSize="small" style={{ marginTop: -5 }} />
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <ArrowDropUpIcon fontSize="small" style={{ marginBottom: -5 }} />
                                                    <ArrowDropDownIcon style={{ marginTop: -5, opacity: 0.5 }} />
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </IconButton>
                                </td>
                                <td style={{ width: "23%" }}> SECURITY GROUP
                                    <IconButton className="form_asc_dsc_button" onClick={handleSortSecurityGroup} style={{ padding: 1 }} >
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
                                            {sortOrderSecurityGroup === "asc" ? (
                                                <React.Fragment>
                                                    <ArrowDropUpIcon style={{ marginBottom: -5, opacity: 0.5 }} />
                                                    <ArrowDropDownIcon fontSize="small" style={{ marginTop: -5 }} />
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <ArrowDropUpIcon fontSize="small" style={{ marginBottom: -5 }} />
                                                    <ArrowDropDownIcon style={{ marginTop: -5, opacity: 0.5 }} />
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </IconButton>
                                </td>
                                <td style={{ width: "5%" }}></td>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.length === 0 ? (<></>)
                                : (filteredData.slice((PageNumber - 1) * numberOfRows, PageNumber * numberOfRows)
                                    .map((e, index) => {
                                        return (
                                            <tr key={index} className={index % 2 === 0 && "row_even"}>
                                                <td className="form_check_container" key={e.id}>
                                                    <Checkbox
                                                        checked={selectedItems.some(item => item.id === e.id && item.name === e.userRoleDisplayName)}
                                                        onChange={() => handleToggleSelect(e.id, e.userRoleDisplayName)}
                                                        icon={<span className="form_check_input" />}
                                                        checkedIcon={
                                                            <CheckIcon className="form_check_input" />
                                                        }
                                                        color="primary"
                                                        inputProps={{ "aria-label": "checkbox" }}
                                                    />
                                                </td>
                                                <td>
                                                    <div className="user_table_display_name">
                                                        {userProfileImages && userProfileImages.length > 0 && (
                                                            userProfileImages.map((imageData) => {
                                                                const { id, decodedImage, imageFilepath } = imageData;
                                                                // Check if the id matches the user id (e.id)
                                                                if (id === e.id) {
                                                                    return (
                                                                        <React.Fragment key={id}>
                                                                            {decodedImage && decodedImage.length > 2 ? (
                                                                                <img
                                                                                    className={`user_image`}
                                                                                    src={decodedImage}
                                                                                    alt="User"
                                                                                />
                                                                            ) : (imageFilepath && imageFilepath.length === 2) ? (
                                                                                <div className='user_initial_circle_content'>
                                                                                    <Typography className='user_intial_text'> {imageFilepath} </Typography>
                                                                                </div>
                                                                            ) : null}
                                                                        </React.Fragment>
                                                                    );
                                                                }
                                                                return null;
                                                            })
                                                        )}
                                                        <p> {e.displayName} </p>
                                                    </div>
                                                </td>
                                                <td> {e.userRoleDisplayName} </td>
                                                <td> {e.userGroupDisplayName} </td>
                                                <td> {e.securityGroupDisplayName} </td>
                                                <td>
                                                    {
                                                        (() => {
                                                            const hasSuperAdminRole = e.userRoleDisplayName.includes('Super Administrator');
                                                            return (
                                                                <IconButton
                                                                    className="user_table_action_menu"
                                                                    aria-controls={`menu-options-${e.id}`}
                                                                    aria-haspopup="true"
                                                                    onClick={(event) => handleOpenMenu(event, e.id)}
                                                                    disabled={
                                                                        !(isSuperAdmin && hasSuperAdminRole) &&
                                                                        !(!isSuperAdmin && !hasSuperAdminRole) &&
                                                                        !(isSuperAdmin)
                                                                    }
                                                                >
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                            );
                                                        })()
                                                    }
                                                    <Menu
                                                        id={`menu-options-${e.id}`}
                                                        anchorEl={anchorEl}
                                                        open={selectedRowId === e.id}
                                                        onClose={handleCloseMenu}
                                                        className="user_table_action_menu_content"
                                                        anchorOrigin={{
                                                            vertical: "bottom",
                                                            horizontal: "center",
                                                        }}
                                                        transformOrigin={{
                                                            vertical: "top",
                                                            horizontal: "right",
                                                        }}
                                                    >
                                                        <MenuItem className="user_table_action_menu_sub_content"
                                                            onClick={() => handleSidebarToggle(e.id)}
                                                        >
                                                            Edit Roles & Groups
                                                        </MenuItem>
                                                        {/* <MenuItem className="user_table_action_menu_sub_content">
                                                            Revoke Role
                                                        </MenuItem> */}
                                                        <MenuItem className="user_table_action_menu_sub_content"
                                                            onClick={() => functionDeleteOpenPopupSingle(e.id)}
                                                        >
                                                            Delete User
                                                        </MenuItem>
                                                    </Menu>
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}
                        </tbody>
                    </table>
                </div>
                <Grid container alignItems="center" justifyContent="space-between" className="user_table_pagination_content" >
                    <Grid item className="user_table_pagination_select_box">
                        <b>Row per pages: </b>
                        <div className="user_table_pagination_dropdown">
                            <Select
                                name="pages"
                                IconComponent={ExpandMoreIcon}
                                value={numberOfRows}
                                onChange={handlePageSizeChange}
                                className="user_table_pagination_dropdown_select"
                                fullWidth
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </div>
                    </Grid>
                    <Grid item className="user_table_pagination_navigation">
                        <Pagination
                            count={totalPages}
                            variant="outlined"
                            siblingCount={0}
                            defaultPage={1}
                            boundaryCount={1}
                            page={PageNumber}
                            onChange={handlePageChange}
                            showFirstButton
                            showLastButton
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{
                                        first: KeyboardDoubleArrowLeftIcon,
                                        last: KeyboardDoubleArrowRightIcon,
                                    }}
                                    component="button"
                                    {...item}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </div>
            <Dialog className="user_delete_box" open={active} onClose={closeDialogPopup} fullWidth >
                <CloseIcon onClick={closeDialogPopup} className="popup_close_icon" />
                <DialogTitle> Delete User </DialogTitle>
                <DialogContent>
                    <DialogContentText> {textDialogbox} </DialogContentText>
                </DialogContent>
                <DialogActions className="user_dialog_box_buttons">
                    <Button onClick={handleDeleteUsers} className="user_roles_sub_button">  Yes </Button>
                    <Button onClick={closeDialogPopup} className="user_delete_btn">  No </Button>
                </DialogActions>
            </Dialog>
            {isSidebarOpen && (
                <Box sx={{ display: "flex" }}>
                    <Drawer sx={{
                        width: 282, flexShrink: 0, backgroundColor: "#cdcdcf",
                        "& .MuiDrawer-paper": {
                            width: 282, // Fixed width
                            top: "10%",
                            borderLeft: "0.5px solid #cdcdcf", // Border
                            background: "#f4f3f8", // Background color
                            boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.1)",
                        },
                    }}
                        variant="persistent"
                        anchor="right"
                        open={isSidebarOpen}
                    >
                        <div className="user_edit_container">
                            <CloseIcon onClick={handleCancel} className="popup_close_icon" />
                            <Typography variant="h7" className="user_edit_select_heading">  Edit Roles & Groups </Typography>
                            <Typography variant="h7" className="user_edit_select_label">  USER NAME </Typography>
                            <TextField
                                className="edit_user_input"
                                type="text"
                                name="editedOption"
                                value={selectedUserData.displayName}
                                disabled
                            />
                            <Typography variant="h7" style={{ marginTop: "20px" }} className="user_edit_select_label" >
                                USER ROLE
                            </Typography>
                            <Select
                                name="editRole"
                                IconComponent={ExpandMoreIcon}
                                value={editedUserRole}
                                onChange={handleEditedUserRoleChange}
                                onClose={handleSelectUserRoleClose}
                                className="user_edit_select_option"
                                multiple
                                fullWidth
                                displayEmpty
                                renderValue={(selected) =>
                                    selected !== "" ? (
                                        <div className="user_edit_selected_values_input">
                                            {selected.map((value, index) => (
                                                <span key={index}>

                                                    {index > 0 ? ", " : ""}
                                                    {userRoles.find((option) => option.id === value)
                                                        ?.userRoleName
                                                    }
                                                </span>
                                            ))}
                                        </div>
                                    ) : ("Select")
                                }
                            >
                                {userRoles.map((option, index) => (
                                    !isSuperAdmin && option.displayName !== "Super Administrator" ? (
                                        <MenuItem className="user_select_sub_select_content" key={index} value={option.id} >
                                            <Checkbox style={{ color: "#49454F" }} checked={editedUserRole.includes(option.id)} />
                                            <ListItemText primary={option.displayName} />
                                        </MenuItem>) :
                                        isSuperAdmin ? (
                                            <MenuItem className="user_select_sub_select_content" key={index} value={option.id} >
                                                <Checkbox style={{ color: "#49454F" }} checked={editedUserRole.includes(option.id)} />
                                                <ListItemText primary={option.displayName} />
                                            </MenuItem>) : null
                                ))}
                            </Select>
                            <Typography variant="h7" style={{ marginTop: "20px" }} className="user_edit_select_label" >
                                USER GROUP
                            </Typography>
                            <TextField
                                minRows={2}
                                maxRows={4}
                                className="user_edit_group_input"
                                type="text"
                                name="usergroup"
                                multiline
                                variant="outlined"
                                disabled
                                value={editUserGroupsAsString.length > 0 ? editUserGroupsAsString : ''}
                            />
                            <div className="save_button">
                                <Button className="user_roles_sub_button" onClick={handleSaveChanges} >
                                    Save Changes
                                </Button>
                            </div>
                        </div>
                    </Drawer>
                </Box>
            )}
        </>
    );
}

export default UserProfile;