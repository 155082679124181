import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import HipOneLogo from "../../../assets/HIP Logo Horizontal.svg";
import IOSSwitch from "./IOSSwitch/IOSSwitch";
import HipOneLightLogo from "../../../assets/HIP Logo_Horizontal_Light.svg";
import "./Navbar.css";

const Navbar = ({ onToggleTheme, darkMode }) => {
  const navigate = useNavigate();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 601);
  const messages = useSelector((state) => state.convMessages);
  const location = useLocation();
  const isContactPage = location.pathname === "/contact";

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const handleOnClick = () => {
    // const loginUrl = `${process.env.REACT_APP_HIP_LOGIN_API_ENDPOINT}`;
    // window.open(loginUrl, "_blank");
    // // Close the mobile menu when an item is clicked
    // setShowMobileMenu(false);
    navigate("/dashboard");
  };

  const navigateToContact = () => {
    navigate("/contact");
    // Close the mobile menu when an item is clicked
    setShowMobileMenu(false);
  };
  const navigateToDashboard = () => {
    navigate("/dashboard");
    // Close the mobile menu when an item is clicked
    setShowMobileMenu(false);
  };

  // Add this handler to navigate back to chatbox interface
  const navigateToChatbox = () => {
    navigate("/"); // Replace 'chatbox' with the actual URL of the chatbox interface
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 601);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`navbar ${darkMode ? "dark-mode" : ""} ${
        messages.length === 0 ? "no-border" : ""
      } ${isContactPage ? "contact-page" : ""}`}
    >
      {" "}
      <div className="navbar-toolbar">
        <div className="logo-container">
          {(isContactPage || messages.length > 0) && (
            // Add onClick handler to the HipOneLogo
            <img
              src={darkMode ? HipOneLightLogo : HipOneLogo}
              alt="Logo"
              className="navbar-logo"
              onClick={navigateToChatbox}
            />
          )}
        </div>
        <div className="privacy-and-switch-container">
          <span className="privacy-label">Privacy mode</span>
          <IOSSwitch
            className="IOSSwitch"
            checked={darkMode}
            onChange={onToggleTheme}
          />
        </div>
        <div className="buttons-container">
          {!isDesktop && (
            <IconButton
              className="mobile-menu-icon"
              onClick={toggleMobileMenu}
              edge="end"
              style={{ marginRight: "4px" }} // Add margin to the right
            >
              <MenuIcon />
            </IconButton>
          )}
          {/* Desktop menu items */}
          {!showMobileMenu && isDesktop && (
            <div className="desktop-menu">
              <button className="login-btn" onClick={handleOnClick}>
                Login
              </button>
              <button className="contact-btn" onClick={navigateToContact}>
                Contact Us
              </button>
            </div>
          )}
        </div>
      </div>
      {/* Mobile menu */}
      <Drawer
        anchor="right"
        open={showMobileMenu}
        onClose={() => setShowMobileMenu(false)}
        classes={{
          paper: "custom-drawer", // Apply the custom styles to the drawer
        }}
      >
        <div className="custom-drawer-content">
          {" "}
          {/* Add content container */}
          <IconButton
            className="custom-drawer-close-icon"
            onClick={() => setShowMobileMenu(false)}
          >
            <CloseIcon />
          </IconButton>
          <List>
            <ListItem
              button
              onClick={handleOnClick}
              className="custom-drawer-item" // Apply styles to list item
            >
              <ListItemText
                primary="Login"
                className="custom-drawer-item-text" // Apply styles to list item text
              />
            </ListItem>
            <ListItem
              button
              onClick={navigateToContact}
              className="custom-drawer-item" // Apply styles to list item
            >
              <ListItemText
                primary="Contact Us"
                className="custom-drawer-item-text" // Apply styles to list item text
              />
            </ListItem>
          </List>
          {/* Close button icon (optional) */}
        </div>
      </Drawer>
    </div>
  );
};

export default Navbar;