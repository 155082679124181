import React from "react";
import "./Demographics.css";
import { useSelector } from "react-redux";

const Demographics = () => {
  const demographics_data = JSON.parse(sessionStorage.getItem("demographics"));

  const theme = useSelector((state) => state.messages.Theme) || ""
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    return `${year}/${month}/${day}`;
  };

  const name = demographics_data?.Name || "N/A";
  const dob = formatDate(demographics_data?.Birthdate);
  const gender = demographics_data?.Gender || "N/A";
  const marital_status = demographics_data?.Marital_Status || "N/A";
  const Street_Address = demographics_data?.Contact_Details?.Street_Address || "N/A";
  const City = demographics_data?.Contact_Details?.City || "N/A";
  const State = demographics_data?.Contact_Details?.State || "N/A";
  const Country = demographics_data?.Contact_Details?.Country || "N/A";
  const Postal_Code = demographics_data?.Contact_Details?.Postal_Code || "N/A";
  const addressComponents = [Street_Address, City, State, Country, Postal_Code].filter(value => value !== "N/A");

  const address = addressComponents.join(", ");

  return (
    <div className={`${theme}information-container`}>
      <table className={`${theme}demographics-table`}>
        <thead>
          <tr>
            <th colSpan={4}>
              <h2>Demographics</h2>
              <hr />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <strong>Name : </strong> {name}
            </td>
            <td>
              <strong>Date of Birth : </strong>
              {dob}
            </td>
            <td>
              <strong>Gender : </strong>
              {gender}
            </td>
            <td>
              <strong>Marital Status : </strong>
              {marital_status}
            </td>
          </tr>
          <tr>
            <td colSpan="4">
              <strong>Address : </strong>
              {address}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Demographics;