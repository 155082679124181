import React, { useEffect, useState } from 'react';
import {
    TextField, Typography, Select, MenuItem, Button, FormHelperText, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Drawer
} from '@mui/material';
import "./Manage.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "react-toastify";
import CreateImage from "../../../assets/Icons/CreatePlus.svg";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import GetCookie from '../../../HIPCookies/getCookie';

function Manage() {
    const [fields, setFields] = useState({
        name: "",
        category: "",
        module: "", // Add module state
    });

    const [editFields, setEditFields] = useState({
        editCategory: "",
        editOption: "",
    });

    const [errors, setErrors] = useState({
        name: false,
        category: false,
        editCategory: false,
        editOption: false,
    });

    const [active, setActive] = useState(false);
    const [activeCreate, setActiveCreate] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [textDialogbox, setTextDialogbox] = useState("");
    const [textDialogboxheading, setTextDialogboxheading] = useState("");
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [editOptionId, setEditOptionId] = useState(null);
    const [editedOption, setEditedOption] = useState("");
    const [selectedModule, setSelectedModule] = useState(""); // Update the state to manage module selection.
    const [moduleOptions, setModuleOptions] = useState([]); // state for "Select Module" dropdown
    const [selectedModuleId, setSelectedModuleId] = useState(null);
    const [selectedModuleEditId, setSelectedModuleEditId] = useState(null);
    const jWTToken = (JSON.parse(GetCookie('token')) || {}).token;
    const userInfo = (JSON.parse(GetCookie('userInfo')) || {}).userId + ":" + (JSON.parse(GetCookie('userInfo')) || {}).userNameDisplay;

    const functionOpenPopup = () => {
        setActive(true);
        if (!isSidebarOpen) {
            editFields.editCategory = "";
            editFields.editOption = "";
            if (fields.category !== "" && fields.name !== "") {
                if (fields.category === "Permission" && fields.module !== "") {
                    setActiveCreate(true);
                    setTextDialogboxheading(`Create ${fields.category}`);
                    setTextDialogbox(`Do you want to create New ${fields.category}?`);
                } else if (fields.category !== "Permission") {
                    setActiveCreate(true);
                    setTextDialogboxheading(`Create ${fields.category}`);
                    setTextDialogbox(`Do you want to create New ${fields.category}?`);
                } else {
                    setActiveCreate(false);
                    setActive(false);
                }
            } else {
                setActiveCreate(false);
                setActive(false);
            }
        } else if (isSidebarOpen) {
            setActiveCreate(false);
            setTextDialogboxheading(`Update ${editFields.editCategory}`);
            setTextDialogbox(`Do you want to update ${editFields.editCategory}?`);
        }
    };

    const closeDialogPopup = () => {
        setActive(false);
    };

    const handleSidebarToggle = () => {
        // Clear the error states for name and category when opening or closing the sidebar
        setErrors((prevErrors) => ({
            ...prevErrors,
            name: false,
            category: false,
        }));
        fields.category = "";
        fields.name = "";
        if (editFields.editCategory === "" || editFields.editOption === "") {
            setIsSidebarOpen(isSidebarOpen);
        } else {
            setIsSidebarOpen(!isSidebarOpen);
        }
    };

    // handle closing of sidebar
    const handleEditCancel = () => {
        // Close the sidebar and clear the editedOption
        setIsSidebarOpen(false);
    };

    const fetchModuleOptions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/PermissionModule`,
                {
                    headers: {
                        'Authorization': `Bearer ${jWTToken}`
                    },
                });

            if (response.status === 200) {
                const options = response.data;
                setModuleOptions(options);
            }

        } catch (error) {
            console.error("Axios Error: ", error);
        }
    };

    useEffect(() => {
        fetchModuleOptions();
    }, []);

    const handleCreateChange = (event) => {
        const { name, value } = event.target;
        setFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const handleEditChange = (event) => {
        const { name, value } = event.target;

        if (name === "editCategory") {
            setEditFields((prevFields) => ({
                ...prevFields,
                [name]: value,
                editOption: "",
            }));
        }

        setEditFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));

        //to be able to see the selected option on sidebar window textfield and edit it
        if (name === "editOption") {
            if (value) {
                const selectedOption = categoryOptions.find(
                    (option) => option.displayName === value
                );
                if (selectedOption) {
                    setSelectedModuleEditId(selectedOption.moduleID);
                }
                if (selectedOption) {
                    if (editFields.editCategory === "Permission") {
                        const [editModule, editValue] =
                            selectedOption.displayName.split(":");
                        setEditedOption(editValue ? editValue.trim() : "");
                        setSelectedModule(editModule ? editModule.trim() : "");
                    } else {
                        setEditedOption(value); // No need for trimming or splitting
                        setSelectedModule(""); // Clear the module if not "Permission"
                    }
                    setEditOptionId(selectedOption.id);
                } else {
                    setEditedOption(""); // Set to an empty string if no selected option
                    setEditOptionId(null);
                    setSelectedModule(""); // Clear the module if no selected option
                }
            } else {
                setEditedOption(""); // Set to an empty string if value is empty
                setEditOptionId(null);
                setSelectedModule(""); // Clear the module if value is empty
            }
        }
    };

    // Add the handleModuleChange function here
    const handleModuleChange = (event) => {
        const selectedModuleValue = event.target.value;
        const { name, value } = event.target;
        setFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));

        // Find the selected module object from moduleOptions based on the value
        const selectedModule = moduleOptions.find(
            (module) => module.displayName === selectedModuleValue
        );

        if (selectedModule) {
            setSelectedModule(selectedModuleValue); // Set the selected module name
            setSelectedModuleId(selectedModule.id); // Set the selected module's ID
            setFields((prevFields) => ({
                ...prevFields,
                module: selectedModuleValue, // Update the module state in fields
            }));
        } else {
            setSelectedModule(""); // Clear the selected module if not found
            setSelectedModuleId(null); // Clear the selected module's ID if not found
            setFields((prevFields) => ({
                ...prevFields,
                module: "", // Clear the module state in fields
            }));
        }
    };

    const handleCreate = (event) => {
        event.preventDefault();
        let formValidCreate = true;
        const updatedErrors = {};

        Object.keys(fields).forEach((field) => {
            if (!fields[field]) {
                updatedErrors[field] = true;
                formValidCreate = false;
            }
        });

        setErrors(updatedErrors);
    };

    const handleEdit = (event) => {
        event.preventDefault();
        let formValidEdit = true;
        const updatedErrors = {};

        Object.keys(editFields).forEach((field) => {
            if (!editFields[field]) {
                updatedErrors[field] = true;
                formValidEdit = false;
            }
        });

        setErrors(updatedErrors);
    };

    const handlePopupCreateOrEdit = () => {
        if (activeCreate) {
            handleCreateEntry();
            closeDialogPopup();
        } else {
            handleEditUpdate();
            closeDialogPopup();
        }
    };

    const handleCreateEntry = async () => {
        try {
            const selectCategory = fields.category;
            if (!selectCategory || !fields.name) {
                // Check if either category or name is empty
                toast.error("Please Enter name and select category.", {
                    position: toast.POSITION.TOP_CENTER,
                });
                return; // Exit the function if any field is empty
            }
            const endpoint = selectCategory.split(" ").join("").trim();
            let fieldName;

            switch (selectCategory) {
                case "User Role":
                    fieldName = "userRoleName";
                    break;
                case "User Group":
                    fieldName = "userGroupName";
                    break;
                case "Security Group":
                    fieldName = "SecurityGroupName";
                    break;
                case "Permission":
                    fieldName = "PermissionName";
                    break;
            }

            await axios.post(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/${endpoint}`,
                {
                    [fieldName]: fields.name,
                    displayName: fields.name,
                    LastUpdatedBy: userInfo,
                    CreatedBy: userInfo,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${jWTToken}`,
                        ModuleId: selectedModuleId,
                    },
                }
            ).then((response) => {
                if (response.status === 200 && response.data != "Data already exist") {
                    toast.success(`Successfully added ${fields.name} as ${selectCategory}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                        }
                    );
                } else {
                    toast.error(
                        `"${selectCategory}: ${fields.name}" is already exist.`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                        }
                    );
                }
            }).then(() => {
                setFields({
                    name: "",
                });
            }).catch((error) => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.error, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                } else {
                    toast.error("An error occured while adding the entry.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            })
                .finally(() => {
                    closeDialogPopup();
                });
        } catch (error) {
            console.error(error);
        }
    };

    //function to fetch values related to categories from database
    const fetchOptions = async (category) => {
        try {
            const endpoint = category.split(" ").join("").trim();
            const response = await axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/${endpoint}`,
                {
                    headers: {
                        'Authorization': `Bearer ${jWTToken}`
                    },
                });

            if (response.status === 200) {
                const options = response.data;
                setCategoryOptions(options);
            }
        } catch (error) {
            console.error("Axios Error: ", error);
        }
    };

    // to fetch the options as soon as category is selected
    useEffect(() => {
        if (editFields.editCategory) {
            fetchOptions(editFields.editCategory);
        }
    }, [editFields.editCategory]);

    // to update the selected option in database
    const handleEditUpdate = () => {
        const selectCategory = editFields.editCategory;
        const endpoint = selectCategory.split(" ").join("").trim();
        let fieldName;
        switch (selectCategory) {
            case "User Role":
                fieldName = "userRoleName";
                break;
            case "User Group":
                fieldName = "userGroupName";
                break;
            case "Security Group":
                fieldName = "SecurityGroupName";
                break;
            case "Permission":
                fieldName = "PermissionName";
                break;
        }
        axios.put(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/${endpoint}/${editOptionId}`,
            {
                [fieldName]: editedOption,
                displayName: editedOption,
                LastUpdatedBy: userInfo,
            },
            {
                headers: {
                    'Authorization': `Bearer ${jWTToken}`,
                    ModuleId: selectedModuleEditId,
                },
            }
        ).then((response) => {
            if (response.status === 200 && response.data != "Data already exist") {
                toast.success(
                    `Successfully updated ${editedOption} as ${selectCategory}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                    }
                );
                setIsSidebarOpen(false);
                setEditFields({
                    editOption: "",
                    editCategory: "",
                });
            } else {
                toast.error(
                    `"${selectCategory}: ${editedOption}" is already exist.`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                    }
                );
            }
        }).catch((error) => {
            console.error("Axios Error: ", error);
            toast.error("An error occurred while updating the entry.", {
                position: toast.POSITION.TOP_CENTER,
            });
        });
    };
    return (
        <div className="manage_roles_main_container">
            <form onSubmit={handleCreate}>
                <Typography variant="h1" className="manage_roles_heading">Create new Permissions, Groups or Roles to add.</Typography>
                <div className="manage_roles_form_content">
                    <div className="manage_roles_form_container">
                        <Typography className="manage_roles_sub_heading" > Create New </Typography>
                        <TextField
                            className="manage_roles_sub_field"
                            type="search"
                            name="name"
                            inputProps={{ maxLength: 100 }}
                            placeholder="Enter new name"
                            hinttext="Enter new name"
                            value={fields.name}
                            onChange={handleCreateChange}
                            helperText={errors.name && "Please enter new name"}
                            error={errors.name}
                            disabled={isSidebarOpen}
                        />
                    </div>
                    <div>
                        <Typography className="manage_roles_sub_heading" > Select Category </Typography>
                        <Select
                            name="category"
                            IconComponent={ExpandMoreIcon}
                            value={fields.category}
                            onChange={handleCreateChange}
                            className="manage_roles_sub_select"
                            fullWidth
                            displayEmpty
                            renderValue={(value) => (value !== "" ? value : "Select")}
                            error={errors.category}
                            disabled={isSidebarOpen}
                        >
                            <MenuItem className="manage_roles_sub_select_content" value="Permission" > Permission </MenuItem>
                            <MenuItem className="manage_roles_sub_select_content" value="Security Group" > Security Group </MenuItem>
                            <MenuItem className="manage_roles_sub_select_content" value="User Group" > User Group </MenuItem>
                            <MenuItem className="manage_roles_sub_select_content" value="User Role" > User Role </MenuItem>
                        </Select>
                        <FormHelperText style={{ color: "#d32f2f" }}>{errors.category && "Please select category"}</FormHelperText>
                    </div>
                    {fields.category === "Permission" &&
                        ( // Conditionally render the "Select Module" dropdown to make it visible when "permission" is selected
                            <div style={{ marginLeft: "20px" }}>
                                <Typography className="manage_roles_sub_heading" > Select Module</Typography>
                                <Select
                                    name="module"
                                    IconComponent={ExpandMoreIcon}
                                    value={fields.module}
                                    onChange={handleModuleChange}
                                    className="manage_roles_sub_select"
                                    fullWidth
                                    displayEmpty
                                    renderValue={(value) => (value !== "" ? value : "Select")}
                                >
                                    {moduleOptions.map((option) => (
                                        <MenuItem key={option.id} className="manage_roles_sub_select_content" value={option.displayName} >
                                            {option.displayName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText style={{ color: "#d32f2f" }}> {errors.module && "Please select module"} </FormHelperText>
                            </div>
                        )}
                </div>
                <div>
                    <Button type="submit" className="manage_roles_sub_button" onClick={functionOpenPopup} disabled={isSidebarOpen} >
                        <img src={CreateImage} alt="create icon" />
                        Create
                    </Button>
                </div>
                <Dialog className="manage_user_dialog_box" open={active} onClose={closeDialogPopup} fullWidth >
                    <CloseIcon onClick={closeDialogPopup} className="popup_close_icon" />
                    <DialogTitle> {textDialogboxheading} </DialogTitle>
                    <DialogContent>
                        <DialogContentText> {textDialogbox} </DialogContentText>
                    </DialogContent>
                    <DialogActions className="manage_user_dialog_box_buttons">
                        <Button onClick={handlePopupCreateOrEdit} className="edit_manage_roles_sub_button" > Yes </Button>
                        <Button onClick={closeDialogPopup} className="edit_user_cancel_btn">No </Button>
                    </DialogActions>
                </Dialog>
            </form>
            <form onSubmit={handleEdit}>
                <Typography variant="h1" className="manage_roles_heading"> Edit Permissions, Groups and Roles label names </Typography>
                <div className="manage_roles_form_content">
                    <div className="manage_roles_form_container">
                        <Typography className="manage_roles_sub_heading" > Select Category </Typography>
                        <Select
                            name="editCategory"
                            IconComponent={ExpandMoreIcon}
                            value={editFields.editCategory}
                            onChange={handleEditChange}
                            className="manage_roles_sub_select"
                            fullWidth
                            displayEmpty
                            renderValue={(value) => (value !== "" ? value : "Select")}
                            error={errors.editCategory}
                            disabled={isSidebarOpen}
                        >
                            <MenuItem className="manage_roles_sub_select_content" value="Permission" > Permission </MenuItem>
                            <MenuItem className="manage_roles_sub_select_content" value="Security Group" > Security Group </MenuItem>
                            <MenuItem className="manage_roles_sub_select_content" value="User Group" > User Group </MenuItem>
                            <MenuItem className="manage_roles_sub_select_content" value="User Role" > User Role </MenuItem>
                        </Select>
                        <FormHelperText style={{ color: "#d32f2f" }}> {errors.editCategory && "Please select category"} </FormHelperText>
                    </div>
                    <div className="manage_roles_form_container">
                        <Typography variant="subtitle1" className="manage_roles_sub_heading" > Select Option </Typography>
                        <Select
                            name="editOption"
                            IconComponent={ExpandMoreIcon}
                            value={editFields.editOption}
                            onChange={handleEditChange}
                            className="manage_roles_sub_select"
                            fullWidth
                            displayEmpty
                            renderValue={(value) => (value !== "" ? value : "Select")}
                            error={errors.editOption}
                            disabled={isSidebarOpen}
                        >
                            {categoryOptions.map((option) => (
                                <MenuItem key={option.id} className="manage_roles_sub_select_content" value={option.displayName}
                                    data-moduleid={option.moduleID}
                                >
                                    {option.displayName}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText style={{ color: "#d32f2f" }}> {errors.editOption && "Please select option"} </FormHelperText>
                    </div>
                    <div className="button_container">
                        <Button type="submit" disabled={isSidebarOpen} className="manage_roles_sub_button" onClick={handleSidebarToggle} >
                            Edit
                        </Button>
                    </div>
                </div>
            </form>
            <div>
                <Box sx={{ display: "flex" }}>
                    <Drawer sx={{
                        width: 300, // Fixed width
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                            width: 300, // Fixed width
                            top: "10%",
                            borderLeft: "0.5px solid #cdcdcf", // Border
                            background: "#f4f3f8", // Background color
                            boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.1)",
                        },
                        backgroundColor: "#cdcdcf",
                    }}
                        variant="persistent"
                        anchor="right"
                        open={isSidebarOpen}
                    >
                        <div className="edit_container">
                            <CloseIcon onClick={handleEditCancel} className="popup_close_icon" />
                            <Typography variant="h7" className="edit_select_heading"> Edit </Typography>
                            {editFields.editCategory === "Permission" && (
                                <div>
                                    <div className="edit_select_label">
                                        <Typography variant="h7"> Module </Typography>
                                    </div>
                                    <TextField
                                        style={{ marginTop: "20px", marginBottom: "20px" }}
                                        className="manage_edit_user_input"
                                        type="search"
                                        inputProps={{ maxLength: 100 }}
                                        name="editedModule" // You can give it a proper name
                                        placeholder="Edit module"
                                        value={selectedModule} // You should use the state variable for the value
                                        disabled // Set the disabled attribute to disable editing of this field
                                    />
                                </div>
                            )}
                            <Typography variant="h7" className="edit_select_label">Edit {editFields.editCategory} </Typography>
                            <TextField
                                style={{ marginTop: "20px", marginBottom: "20px" }}
                                className="manage_edit_user_input"
                                type="search"
                                inputProps={{ maxLength: 100 }}
                                name="editedOption"
                                placeholder="Edit option"
                                value={editedOption}
                                onChange={(e) => setEditedOption(e.target.value)}
                                helperText={errors.editOption && "Please enter an option"}
                                error={errors.editOption}
                            />
                            <div className="edit_option_buttons">
                                <Button className="edit_user_cancel_btn" onClick={handleEditCancel} > Cancel </Button>
                                <Button className="edit_manage_roles_sub_button" onClick={functionOpenPopup} > Update </Button>
                            </div>
                        </div>
                    </Drawer>
                </Box>
            </div>
        </div>
    );
}
export default Manage;