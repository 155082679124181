import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {
  Select, Typography, MenuItem, Button, FormControl, FormLabel, Box, FormGroup, FormControlLabel, Checkbox, FormHelperText, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Dialog
} from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckBoxNull from "../../../../assets/Icons/Checkboxnull.svg";
import CheckBoxTick from "../../../../assets/Icons/Checkbox_tick.svg";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import GetCookie from '../../../../HIPCookies/getCookie';

function SecurityToUserGroup() {

  const [userGroups, setUserGroups] = useState([]);
  const [securityGroups, setSecurityGroups] = useState([]);
  const [selectedSecurityGroups, setSelectedSecurityGroups] = useState([]);
  const [selectAllEnabled, setSelectAllEnabled] = useState(true);
  const [clearAllEnabled, setClearAllEnabled] = useState(false);
  const [selectedUserGroup, setSelectedUserGroup] = useState([]);
  const [selectedUserGroupId, setSelectedUserGroupId] = useState(null);
  const [active, setActive] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const jWTToken = (JSON.parse(GetCookie('token')) || {}).token;
  const userInfo = (JSON.parse(GetCookie('userInfo')) || {}).userId + ":" + (JSON.parse(GetCookie('userInfo')) || {}).userNameDisplay;


  useEffect(() => {
    getSelectedSecurityGroups();
  }, [selectedUserGroup]);

  useEffect(() => {
    fetchUserGroups();
    fetchSecurityGroups();
  }, []);

  useEffect(() => {
    // Check if all user groups are selected
    const allUserRoleIds = securityGroups.map((item) => item.id);
    const isAllSelected = selectedSecurityGroups.length === allUserRoleIds.length;
    setSelectAllEnabled(!isAllSelected);
    setClearAllEnabled(isAllSelected);
  }, [selectedSecurityGroups, securityGroups]);


  // for handleSaveChanges()
  useEffect(() => {
    if (selectedUserGroup) {
      const selectedUserGroupArr = userGroups.filter(
        (user) => user.displayName === selectedUserGroup
      );

      if (selectedUserGroupArr.length > 0) {
        const selectedId = parseInt(selectedUserGroupArr[0].id);

        setSelectedUserGroupId(selectedId);
      }
    }
  }, [selectedUserGroup, userGroups]);

  //popup function to be used during savechanges
  const functionOpenPopup = () => {
    if (selectedUserGroup.length !== 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }

  const closeDialogPopup = () => {
    setActive(false);
  };

  const fetchUserGroups = () => {
    axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/UserGroup`,
      {
        headers: {
          'Authorization': `Bearer ${jWTToken}`
        },
      })
      .then((response) => {
        setUserGroups(response.data);
      })
      .catch((error) => {
        console.error(error);
      })

  }
  const fetchSecurityGroups = () => {
    axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/SecurityGroup`,
      {
        headers: {
          'Authorization': `Bearer ${jWTToken}`
        },
      })
      .then((response) => {
        setSecurityGroups(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
  }

  // Function to chunk the securityGroups into rows of 4
  const chunkArray = (array, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  const handleCheckboxChange = (id) => {
    setSelectedSecurityGroups((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const getSelectedSecurityGroups = () => {

    if (selectedUserGroup) {
      const selectedUserGroupObject = userGroups.find(
        (group) => group.displayName === selectedUserGroup
      );

      if (selectedUserGroupObject) {
        const selectedUserGroupId = selectedUserGroupObject.id;
        axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/SecurityGroup/GroupIds`,
          {
            headers: {
              'Authorization': `Bearer ${jWTToken}`,
              UserGroupIds: selectedUserGroupId,
            },
          }
        )
          .then((response) => {
            if (response.data.length !== 0) {
              setSelectedSecurityGroups(response.data.map(securityGroups => securityGroups.id));
            }
            else {
              setSelectedSecurityGroups([]);
            }
            // Automatically check the checkboxes of pre-assigned user groups
          })
          .catch((error) => {
            console.error(error);
            toast.error("Invalid Security Group selected", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    } else {
      // empty the checkbox when clicked on "Select"
      setSelectedSecurityGroups([]);
    }
  };

  const handleSelectAll = () => {
    const allSecurityGroupIds = securityGroups.map((item) => item.id);
    setSelectedSecurityGroups(allSecurityGroupIds);
  };

  const handleClearAll = () => {
    setSelectedSecurityGroups([]);
  };

  // handle for resetting security groups
  const handleSelectChange = (e) => {
    const selectedDisplayName = e.target.value;
    setFormSubmitted(false)
    if (selectedDisplayName === 'Select' || selectedDisplayName === '') {
      // Reset the state when 'Select' option is selected
      setSelectedUserGroup([]);
      setSelectedSecurityGroups([]);
    } else {
      setSelectedUserGroup(selectedDisplayName);
    }
  };

  const updateUserGroup = () => {
    if (selectedUserGroup.length === 0) {
      toast.error('Please select at least one UserGroup to Assign Security Group', {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (selectedUserGroup) {
      const requestData = {
        userGroupName: selectedUserGroup,
        displayName: selectedUserGroup,
        LastUpdatedBy: userInfo,
      };

      const headers = {
        'Authorization': `Bearer ${jWTToken}`,
        SecurityGroupId: selectedSecurityGroups.length > 0 ? selectedSecurityGroups : [0],
        relation: "true",
      };

      axios.put(`${process.env.REACT_APP_USER_API_ENDPOINT}/api/v1/UserGroup/${selectedUserGroupId}`, requestData,
        { headers: headers }
      )
        .then((response) => {
          if (response.status === 200 && response.data !== "Data already exist") {
            toast.success(" Security Groups Assigned Successfully to User Group", {
              position: toast.POSITION.TOP_CENTER
            });
            closeDialogPopup();
            setSelectedSecurityGroups([]); //clear all securitygroups checkboxes after update 
            setSelectedUserGroup([]);   // Reset the selectedUserGroup state to the default value after updating
            setFormSubmitted(false); // Reset formSubmitted state here
            setSelectAllEnabled(false);
          }
        })
        .catch((error) => {
          console.error("Error updating user group:", error);
          toast.error("Error updating User Group", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
  };

  return (
    <form onSubmit={handleSubmit} className='security_role_container'>
      <Typography variant='h7' className='header_container'> Assign Security Groups To User Group </Typography>
      <div className='security_role_form_container'>
        <Typography variant="h7" className="security_role_form_heading" > USER GROUP </Typography>
        <Select
          name="userGroup"
          IconComponent={ExpandMoreIcon}
          className='security_role_select'
          fullWidth
          displayEmpty
          value={selectedUserGroup}
          renderValue={(value) => (value.length !== 0 ? value : "Select")}
          defaultValue="Select"
          error={formSubmitted && selectedUserGroup.length === 0}
          onChange={handleSelectChange}
        >
          <MenuItem className="select_content" value="Select"> Select </MenuItem>
          {userGroups.map((item) => (
            <MenuItem className="select_content" key={item.id} value={item.displayName}> {item.displayName} </MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ color: "#d32f2f" }}>{formSubmitted && !selectedUserGroup.length && "Please select User Group"}</FormHelperText>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl className='roles_permissions_form_container' component="fieldset" variant="standard">
          {chunkArray(securityGroups, 4).map((row, rowIndex) => (
            <div key={rowIndex} className="checkbox_row">
              {row.map((item) => (
                <label className="roles_permission_checkbox" key={item.id}>
                  <Checkbox
                    checked={selectedSecurityGroups.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                    checkedIcon={<img src={CheckBoxTick} alt='checkbox' />}
                    icon={<img src={CheckBoxNull} alt='checkbox' />}
                    color="primary"
                    inputProps={{ "aria-label": "checkbox" }}
                  />
                  {item.displayName}
                </label>
              ))}
            </div>
          ))}
        </FormControl>
      </Box>
      <Dialog className="roles_permission_dialog_box" open={active} onClose={closeDialogPopup} fullWidth >
        <CloseIcon onClick={closeDialogPopup} className="popup_close_icon" />
        <DialogTitle> {"Assign Security Groups To User Group"} </DialogTitle>
        <DialogContent>
          <DialogContentText>{`Do you want to update User Group : ${selectedUserGroup}`}</DialogContentText>
        </DialogContent>
        <DialogActions className="roles_permission_dialog_box_buttons">
          <Button onClick={updateUserGroup} className="roles_permission_sub_button" > Yes </Button>
          <Button onClick={closeDialogPopup} className="roles_permission_cancel_btn">No </Button>
        </DialogActions>
      </Dialog>
      <FormControl className='buttons_form_container' >
        <Button type="button" className={`all_button ${!selectAllEnabled || selectedUserGroup === "Select" || selectedUserGroup === "" || selectedUserGroup.length === 0 ? 'disabled-button' : ''}`}
          onClick={handleSelectAll} disabled={!selectAllEnabled || selectedUserGroup === "Select" || selectedUserGroup === ""}>
          Select All
        </Button>
        <Button type="button" className={`all_button ${!clearAllEnabled || selectedUserGroup.length === 0 ? 'disabled-button' : ''}`} onClick={handleClearAll}
          disabled={!clearAllEnabled}
        >
          Clear All
        </Button>
        <Button type="submit" className='Save_changes_button' onClick={functionOpenPopup}> Save Changes </Button>
      </FormControl>
    </form>
  )

}


export default SecurityToUserGroup;