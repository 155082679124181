import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Cryptojs from 'crypto-js'
import { useDispatch } from 'react-redux'
import { setLoadingScreen, setNormalAlert } from '../../../Redux/features/AlertsSlice'
import Axios from 'axios'
import DocumentDisplay from './DocumentDisplay/DocumentDisplay'
import "./VerifyMedicalBills.css"
import { ZoomIn, ZoomOut } from '@mui/icons-material'
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdNavigateNext, MdNavigateBefore } from 'react-icons/md'
import BackImage from '../../../assets/Icons/navigate-back.svg'
import DocumentTableDisplay from './DocumentTableDisplay/DocumentTableDisplay'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { setDocumentsList } from '../../../Redux/features/DocumentsSlice';
// import MenuContainer from '../../../Components/NavigationBar/index';
import GetCookie from '../../../HIPCookies/getCookie';

function VerifyMedicalBills() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userName = (JSON.parse(GetCookie('userInfo')) || {}).userNameDisplay;
    const params = useParams();
    const [status, setStatus] = useState('');

    // For Pagination of Images
    const [page, setPage] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const numberOfRows = 1;

    const [FileImages, setFileImages] = useState([])
    const [File, setFile] = useState([])
    const [updatedFile, setUpdatedFile] = useState([])

    const [navigateTo, setNavigateTO] = useState('')
    const [download, setDownload] = useState('')

    const SECRET_KEY_PARAMS = 'JEOFH-JFOEH-EOFDH-KHIEO-OADOE-PIADD'

    const [filename, setFilename] = useState('')

    useEffect(() => {

        dispatch(setLoadingScreen({
            isActive: true
        }))

        let file_data = JSON.parse(Cryptojs.AES.decrypt(params.file_id.split('|').join('/'), SECRET_KEY_PARAMS).toString(Cryptojs.enc.Utf8))

        setFilename(file_data.filename)
        setStatus(file_data.Status)
        setNavigateTO(file_data.navigateTo)

        if (file_data.Status === 'Completed') {
            Axios.post(`${process.env.REACT_APP_API_ENDPOINT}/download_document?file_id=${file_data.Id}`).then((result) => {
                setDownload(result.data.response)
            })
        }

        let images = []

        Axios.post(`${process.env.REACT_APP_API_ENDPOINT}/ocr/man_rev_sc?file_id=${file_data.Id}`).then((res) => {
            if (res.data.status) {
                setFile(res.data.response)
                setPageNumber(res.data.response.data_std.tables.tb_std_array[0].page_number)
                setTimeout(() => {
                    dispatch(setLoadingScreen({
                        isActive: false
                    }))
                }, 1000)

                res.data.response.data_std.tables.tb_std_array.forEach((e) => {
                    images.push({ img_url: e.img_url, img_res: e.img_res })
                })

                setFileImages(images)
                setTotalPages(images.length)
            }
        })

    }, [params.file_id, dispatch])

    const changePage = (number) => {
        setPage(number - numberOfRows)
        setPageNumber(number)

    }

    const handleNext = () => {
        if (pageNumber < totalPages) {
            setPage(page + numberOfRows)
            setPageNumber(pageNumber + 1)
        }
    }

    const handlePrev = () => {

        if (page !== 0) {
            setPage(page - numberOfRows)
            setPageNumber(pageNumber - 1)
        }
    }

    const handleFarLeft = () => {
        setPage(0)
        setPageNumber(1)
    }

    const handleFarRight = () => {
        setPage(totalPages - 1)
        setPageNumber(totalPages)
    }

    const initialValue = 600

    useEffect(() => {
        setZoom(initialValue)
    }, [])

    const [zoom, setZoom] = useState(600)

    const handleZoom = () => {
        if (zoom < 1300) {
            setZoom(zoom + 100)
        }
    }

    const handleZoomOut = () => {
        if (zoom > 400) {
            setZoom(zoom - 100)
        }
    }

    const [tabSpan, setTabSpan] = useState(0)

    const handleSpan = (num) => {
        setPage(num)
        setPageNumber(num + 1)
        setTabSpan(num * 100)
    }

    const handleReviewed = async (option) => {

        dispatch(setLoadingScreen({
            isActive: true
        }))

        if (updatedFile.length === 0) {
            File.status = option === 'save' ? 'save' : 'complete';
            File.user = userName;
            await Axios.post(`${process.env.REACT_APP_API_ENDPOINT}/update_Documents`, File)

            // update the list of documents......
            let result = await Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/list_of_Documents`)
            dispatch(setDocumentsList(result.data))
            navigate(`/hip-operations${navigateTo}`)
            dispatch(setNormalAlert({
                isActive: true,
                message: option === 'save' ? "Changes are Saved" : "Successfully Updated...",
                variant: "success"
            }))

            setTimeout(() => {
                dispatch(setNormalAlert({
                    isActive: false,
                }))
            }, 3000)
        }
        else {
            updatedFile.status = option === 'save' ? 'save' : 'complete'
            updatedFile.user = userName
            await Axios.post(`${process.env.REACT_APP_API_ENDPOINT}/update_Documents`, updatedFile)
            // update the list of documents......
            let result = await Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/list_of_Documents`)

            dispatch(setDocumentsList(result.data))
            navigate(`/hip-operations${navigateTo}`)

            dispatch(setNormalAlert({
                isActive: true,
                message: option === 'save' ? "Changes are Saved" : "Successfully Updated...",
                variant: "success"
            }))

            setTimeout(() => {
                dispatch(setNormalAlert({
                    isActive: false,
                }))
            }, 3000)
        }

        dispatch(setLoadingScreen({
            isActive: false
        }))

    }

    return (
        <>
        <div className='verify_document_medical_bills'>
            {/* Document Display */}
            <div className='verify_document_medical_bills_doc_display'>
                <div className='verify_document_medical_bills_doc_display_header'>
                    <div className='verify_document_medical_bills_doc_display_filename'>
                        <b>Filename</b><br />
                        <span>{filename}</span>
                    </div>
                    <div className='verify_document_medical_bills_doc_display_pagination'>
                        <div className='navigate_icons' onClick={handleFarLeft}>
                            <MdKeyboardDoubleArrowLeft />
                        </div>
                        <div className='navigate_icons' onClick={handlePrev}>
                            <MdNavigateBefore />
                        </div>
                        {/* Numbers */}
                        {
                            FileImages.map((item, index) => {
                                return (
                                    <div className={pageNumber === (index + 1) ? 'navigate_icons navigate_icons_active' : 'navigate_icons'}
                                        onClick={() => changePage(index + 1)}
                                    >{index + 1}</div>
                                )
                            })
                        }
                        <div className='navigate_icons' onClick={handleNext}>
                            <MdNavigateNext />
                        </div>
                        <div className='navigate_icons' onClick={handleFarRight}>
                            <MdKeyboardDoubleArrowRight />
                        </div>
                    </div>
                    {
                        status === 'Completed' && (
                            <div className='verify_document_medical_bills_doc_display_download_btn'>
                                <button>
                                    <a href={download} target="blank">
                                        <FileDownloadOutlinedIcon style={{ color: "#fff", fontSize: "24px" }} />
                                    </a>
                                </button>
                            </div>
                        )
                    }
                </div>
                <div className='verify_document_medical_bills_doc_display_content_zoom'>
                    <ZoomIn
                        fontSize='small'
                        style={{ color: '#5B5B5B', cursor: "pointer" }}
                        onClick={handleZoom}
                    />
                    <div className='verify_document_medical_bills_doc_display_content_zoom_divider'></div>
                    <ZoomOut
                        fontSize='small'
                        style={{ color: '#5B5B5B', cursor: "pointer" }}
                        onClick={handleZoomOut}
                    />
                </div>
                <div className='verify_document_medical_bills_doc_display_content'>
                    {/* use positioning */}

                    {/* Doc Display */}
                    <div className='verify_document_medical_bills_doc_display_content_images' >
                        <DocumentDisplay
                            FileImages={FileImages}
                            page={page}
                            pageNumber={pageNumber}
                            zoom={zoom}
                        />
                    </div>
                </div>
            </div>
            {/* Tabular Data */}
            <div className='verify_document_medical_bills_table_dispaly'>
                <div className='verify_document_medical_bills_table_dispaly_header'>
                    <span>Bill Data</span>
                    <div className='verify_document_medical_bills_table_dispaly_header_btns'>
                        {
                            status !== 'Completed' && (
                                <>
                                    <button onClick={() => handleReviewed('complete')}>Submit</button>
                                    <button onClick={() => handleReviewed('save')}>Save Changes</button>
                                </>
                            )
                        }
                    </div>
                </div>
                {/* Tabs */}
                <div className='verify_document_medical_bills_table_dispaly_table_tabs'>
                    {
                        FileImages.map((item, index) => {
                            return (
                                <div className='verify_document_medical_bills_table_dispaly_table_tab_item' onClick={() => handleSpan(index)} >
                                    <span>Table {index + 1}</span>
                                </div>
                            )
                        })
                    }
                    <div className='verify_document_table_tabs_span' style={{ left: tabSpan }}></div>
                </div>
                {
                    File.id === undefined ?
                        <></>
                        :
                        <DocumentTableDisplay
                            File={File}
                            page={page}
                            pageNumber={pageNumber}
                            setUpdatedFile={setUpdatedFile}
                            status={status}
                        />
                }
            </div>
        </div>
        </>
    )
}

export default VerifyMedicalBills;